<template>
  <b-card
    no-body
  >
    <div class="m-2">
      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Mostrar</label>
          <v-select
            v-model="perPage"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Buscar..."
            />
          </div>
        </b-col>
      </b-row>

    </div>

    <b-table
      class="position-relative"
      hover
      responsive
      small
      :busy="tableIsBusy"
      primary-key="id"
      show-empty
      empty-text="No se encontraron registros coincidientes"
      :items="customerOrderData"
      :fields="customerOrderFields"
      :per-page="perPage"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle" />
        </div>
      </template>

      <template #head(Id)="data">
        <span
          class="text-secondary pointer"
          @click="sortColumn(data.column)"
        >{{ data.label }}
        </span>
      </template>

      <template #head(Estado)="data">
        <span
          class="text-secondary pointer"
          @click="sortColumn(data.column)"
        >{{ data.label }}
        </span>
      </template>

      <template #head(Cliente)="data">
        <span
          class="text-secondary pointer"
          @click="sortColumn(data.column)"
        >{{ data.label }}
        </span>
      </template>

      <template #head(Usuario)="data">
        <span
          class="text-secondary pointer"
          @click="sortColumn(data.column)"
        >{{ data.label }}
        </span>
      </template>

      <template #head(FechaIngresoSistema)="data">
        <span
          class="text-secondary pointer"
          @click="sortColumn(data.column)"
        >{{ data.label }}
        </span>
      </template>

      <!-- Column: Id -->
      <template #cell(Id)="data">
        <b-link
          class="font-weight-bold text-warning"
          @click="$emit('addOrderTab', data.item.Id)"
        >
          #{{ data.item.Id }}
        </b-link>
      </template>

      <!-- Column: Status -->
      <template #cell(Estado)="data">
        <b-badge
          :variant="getStatusVariant(data.item.Estado)"
          class="mr-1"
        >
          {{ data.item.Estado }}
        </b-badge>
      </template>

      <!-- Column: Facturas -->
      <template #cell(Facturas)="data">
        <div v-if="data.item.Facturas">
          <b-badge
            v-for="(factura, index) in data.item.Facturas.split(',')"
            :key="index"
            variant="light-dark"
            class="mr-1"
          >
            {{ factura.trim() }}
          </b-badge>
        </div>
        <span
          v-else
          class="text-muted"
        >
          Sin facturas
        </span>
      </template>

      <template #cell(Total)="data">
        Q.{{ data.item.Total }}
      </template>

    </b-table>

    <div class="mx-2 mb-2">
      <b-row>
        <!-- Pagination info -->
        <b-col
          v-if="customerOrderMeta"
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Mostrando del {{ customerOrderMeta.from }} al {{ customerOrderMeta.to }} de {{ customerOrderMeta.total }} registros</span>
        </b-col>

        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-if="customerOrderMeta"
            v-model="currentPage"
            :total-rows="customerOrderMeta.total"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import axios from '@axios'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
  },

  data() {
    return {
      searchQuery: '',

      sortField: 'Id',
      sortDesc: 'desc',

      perPage: 10,
      perPageOptions: [5, 10, 25, 50, 100],
      currentPage: 1,

      customerOrderFields: [
        { key: 'Id', label: '#' },
        { key: 'Estado', label: 'Estado' },
        { key: 'Facturas', label: 'Factura(s)' },
        { key: 'Cliente', label: 'Cliente' },
        { key: 'Usuario', label: 'Vendedor' },
        { key: 'Productos', label: 'Productos' },
        { key: 'Cantidad', label: 'Cantidad' },
        { key: 'Total', label: 'Total' },
        { key: 'FechaPedidoCliente', label: 'Pedido el' },
        { key: 'FechaIngresoSistema', label: 'Ingresado el' },
      ],
      customerOrderData: null,
      customerOrderMeta: null,
      tableIsBusy: true,
    }
  },

  watch: {
    perPage() {
      this.tableIsBusy = true
      this.getCustomerOrders()
    },
    currentPage() {
      this.tableIsBusy = true
      this.getCustomerOrders()
    },
    searchQuery() {
      this.tableIsBusy = true
      this.getCustomerOrders()
    },
  },

  created() {
    this.getCustomerOrders()
  },

  methods: {
    paymentTypeInfo(data) {
      switch (data.value) {
        case 'efectivo':
          return {
            color: 'light-success',
            text: 'Efectivo',
          }
        case 'tarjeta':
          return {
            color: 'light-warning',
            text: 'Tarjeta',
          }
        case 'credito':
          return {
            color: 'light-danger',
            text: 'Crédito',
          }
        default:
          return {
            color: 'light-secondary',
            text: 'Otro',
          }
      }
    },

    getStatusVariant(estado) {
      switch (estado) {
        case 'Pendiente':
          return 'info'
        case 'Autorizado':
          return 'success'
        case 'Correcciones':
          return 'warning'
        case 'Rechazado':
          return 'danger'
        case 'Anulado':
          return 'danger'
        case 'Despachado':
          return 'secondary'
        case 'Facturado':
          return 'dark'
        default:
          return 'secondary'
      }
    },

    getCustomerOrders() {
      if (this.perPage === 'Todos') {
        this.perPage = this.customerOrderMeta.total
      }

      axios
        .get(`accounting-system/customer-order?perPage=${this.perPage}&page=${this.currentPage}&query=${this.searchQuery}&sortField=${this.sortField}&sortDesc=${this.sortDesc}`)
        .then(response => {
          this.tableIsBusy = false
          this.customerOrderMeta = response.data
          this.customerOrderData = response.data.data
        })
        .catch(error => {
          this.showErrors(error)
        })
    },

    sortColumn(field) {
      if (this.sortField === field) {
        if (this.sortDesc === 'asc') {
          this.sortDesc = 'desc'
        } else {
          this.sortDesc = 'asc'
        }
      } else {
        this.sortField = field
        this.sortDesc = 'asc'
      }
      this.getCustomerOrders()
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.pointer {
  cursor: pointer;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
