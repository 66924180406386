<template>
  <section class="invoice-preview-wrapper">
    <b-row class="invoice-add">

      <!-- Col: Left (Customer Order Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          class="invoice-preview-card"
          no-body
        >
          <b-card-body>
            <!-- Estado del pedido -->
            <b-row>
              <b-col
                cols="12"
                md="11"
              >
                <h3>
                  <b-badge
                    :variant="getStatusVariant(customerOrderData.Estado)"
                    pill
                  >
                    #{{ customerOrderData.Id }} - {{ customerOrderData.Estado }}
                  </b-badge>
                </h3>
              </b-col>

              <b-col
                v-if="customerOrderData.Estado === 'Pendiente' || customerOrderData.Estado === 'Correcciones'"
                cols="12"
                md="1"
                right
              >
                <!-- Pencil feather icon -->
                <feather-icon
                  icon="Edit2Icon"
                  size="24"
                  class="cursor-pointer"
                  @click="$emit('addEditTab')"
                />
              </b-col>
            </b-row>

            <!-- Info de encabezado -->
            <b-row class="mt-2">
              <b-col
                cols="12"
                xl="6"
                md="6"
              >
                <!-- Info del cliente -->
                <p
                  style="font-size: 13.5px;"
                >
                  <!-- Nombre -->
                  <span class="font-weight-bold">Cliente:</span>
                  {{ customerOrderData.CustomerInfo.nombre }}
                  <br>
                  <!-- NIT -->
                  <span class="font-weight-bold">NIT:</span>
                  {{ (customerOrderData.CustomerInfo.nit_cf === 'Y') ? `Consumidor final - ${customerOrderData.CustomerInfo.nit}` : customerOrderData.CustomerInfo.nit }}
                  <br>
                  <!-- Tipo de cliente -->
                  <span class="font-weight-bold">Tipo de cliente:</span>
                  {{ (customerOrderData.CustomerInfo.tipo_desc) ? customerOrderData.CustomerInfo.tipo_desc : '' }}
                  <br>
                  <!-- Lista de precios -->
                  <span class="font-weight-bold">Lista de precios:</span>
                  {{ customerOrderData.CustomerInfo.listap_desc }}
                  <br>
                  <!-- Dirección -->
                  <span class="font-weight-bold">Dirección de cobro:</span>
                  {{ (customerOrderData.CustomerInfo.direccion) ? customerOrderData.CustomerInfo.direccion : '-' }}
                  <br>
                  <!-- Dirección de factura -->
                  <span class="font-weight-bold">Dirección de factura:</span>
                  {{ (customerOrderData.CustomerInfo.direccion_factura) ? customerOrderData.CustomerInfo.direccion_factura : '' }}
                  <br>
                  <!-- Dirección de entrega -->
                  <span class="font-weight-bold">Dirección de entrega:</span>
                  {{ (customerOrderData.CustomerInfo.direccion_entrega) ? customerOrderData.CustomerInfo.direccion_entrega : '' }}
                  <br>
                  <!-- Departamento y municipio -->
                  <span class="font-weight-bold">Departamento:</span>
                  {{ (customerOrderData.CustomerInfo.depto) ? customerOrderData.CustomerInfo.depto : '-' }}
                  <br>
                  <span class="font-weight-bold">Municipio:</span>
                  {{ (customerOrderData.CustomerInfo.muni) ? customerOrderData.CustomerInfo.muni : '-' }}
                </p>
              </b-col>
              <b-col
                cols="12"
                xl="6"
                md="6"
              >
                <p
                  style="font-size: 13.5px;"
                >
                  <span class="font-weight-bold">Fecha del pedido:</span>
                  {{ customerOrderData.FechaPedidoCliente }}
                  <br>
                  <span class="font-weight-bold">Colocado por:</span>
                  {{ customerOrderData.UsuarioNombre }} <b>el:</b> {{ customerOrderData.FechaIngresoSistema }}
                  <br>
                  <span class="font-weight-bold">Observaciones: </span>
                  {{ (customerOrderData.Observaciones) ? customerOrderData.Observaciones : 'No hay observaciones' }}
                </p>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Detalle del pedido -->
          <b-card-body class="invoice-padding form-item-section">
            <b-table
              responsive
              :items="customerOrderData.Detalle"
              :fields="CustomerOrderFields"
            >
              <template #cell(precio_pedido)="data">
                Q.{{ data.item.precio_pedido }}
              </template>
              <template #cell(total)="data">
                Q.{{ data.item.total }}
              </template>
            </b-table>
            <div
              v-if="customerOrderData.SummaryInfo[0]"
              class="float-right mr-4"
            >
              Total:
              <h4 _class="sale-total-amount">
                Q.{{ customerOrderData.SummaryInfo[0].total }}
              </h4>
            </div>
          </b-card-body>
        </b-card>

        <b-card
          class="invoice-preview-card"
          no-body
        >
          <b-card-body>
            <!-- Estado del pedido -->
            <b-row>
              <b-col
                cols="12"
                md="12"
              >
                <h4
                  v-if="(customerOrderData.Comentarios.length > 0)"
                  class="font-weight-bold mt-2"
                >
                  Comentarios:
                </h4>

                <div
                  v-for="(comment, index) in customerOrderData.Comentarios"
                  :key="index"
                  class="mt-1"
                >
                  <!-- Si el comentario es tipo = user -->
                  <p v-if="comment.tipo === 'user'">
                    <span class="font-weight-bold">
                      {{ comment.usuario }}
                    </span>
                    <span class="float-right font-italic">
                      {{ comment.fecha }}
                    </span>
                    <br>
                    {{ comment.comentario }}
                  </p>

                  <p v-if="comment.tipo === 'system'">
                    <span class="text-info">
                      <b>{{ comment.usuario }}</b> realizó una acción:
                    </span>
                    <span class="float-right font-italic text-info">
                      {{ comment.fecha }}
                    </span>
                    <br>
                    <span class="text-info">
                      {{ comment.comentario }}
                    </span>
                  </p>

                  <!-- Spacer -->
                  <hr class="invoice-spacing">
                </div>

                <!-- Textarea para agregar comentarios -->
                <b-form-group
                  class="mb-0"
                >
                  <b-form-textarea
                    id="commentText"
                    v-model="commentText"
                    placeholder="Agregar comentario"
                  />

                  <!-- boton alineado a la derecha -->
                  <b-button
                    variant="primary"
                    :disabled="commentText.length === 0 || disabledAction === 'disabled'"
                    class="float-right mt-1"
                    @click="addComment"
                  >
                    Comentar
                  </b-button>
                </b-form-group>

              </b-col>
            </b-row>
          </b-card-body>
        </b-card>

      </b-col>

      <!-- Col: Right (Actions Container) -->
      <b-col
        v-if="customerOrderData.Estado === 'Pendiente' ||
          (
            userData().role === 'admin' &&
            (customerOrderData.Estado === 'Autorizado' || customerOrderData.Estado === 'Despachado' || customerOrderData.Estado === 'Facturado')
          )"
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions mt-md-0 mt-2"
      >
        <!-- Action Buttons -->
        <b-card>
          <b-button
            v-if="customerOrderData.Estado === 'Pendiente' && userData().role === 'admin'"
            v-ripple.400="'rgba(255, 159, 67, 0.15)'"
            variant="outline-success"
            block
            @click="changeStatus('Autorizado')"
          >
            Autorizar
          </b-button>

          <b-button
            v-if="customerOrderData.Estado === 'Pendiente' && userData().role === 'admin'"
            v-ripple.400="'rgba(255, 159, 67, 0.15)'"
            variant="outline-warning"
            block
            @click="changeStatus('Correcciones')"
          >
            Solicitar correcciones
          </b-button>

          <b-button
            v-if="(customerOrderData.Estado === 'Pendiente' || customerOrderData.Estado === 'Autorizado') && userData().role === 'admin'"
            v-ripple.400="'rgba(255, 159, 67, 0.15)'"
            variant="outline-danger"
            block
            @click="changeStatus('Rechazado')"
          >
            Rechazar
          </b-button>

          <b-button
            v-if="customerOrderData.Estado === 'Pendiente' && userData().role === 'vendor'"
            v-ripple.400="'rgba(255, 159, 67, 0.15)'"
            variant="outline-danger"
            block
            @click="changeStatus('Anulado')"
          >
            Anular
          </b-button>

          <b-button
            v-if="customerOrderData.Estado === 'Autorizado' && userData().role === 'admin'"
            v-ripple.400="'rgba(255, 159, 67, 0.15)'"
            variant="outline-secondary"
            block
            @click="changeStatus('Despachado')"
          >
            Marcar como despachado
          </b-button>

          <!-- Si está despachado puede marcarlo como Facturado -->
          <b-button
            v-if="customerOrderData.Estado === 'Despachado' && userData().role === 'admin'"
            v-ripple.400="'rgba(255, 159, 67, 0.15)'"
            variant="outline-dark"
            block
            @click="changeStatus('Facturado')"
          >
            Marcar como facturado
          </b-button>

          <!-- Si está facturado puede ver el listado de las facturas -->
          <div
            v-if="customerOrderData.Estado === 'Facturado' && userData().role === 'admin'"
          >
            <!-- Editar -->
            <feather-icon
              icon="EditIcon"
              size="20"
              class="cursor-pointer float-right"
              @click="editInvoices"
            />
            <br>
            <p class="mt-1">
              Pedido facturado con las siguientes facturas:
            </p>
            <b-table
              class="position-relative"
              hover
              responsive
              small
              :items="customerOrderData.Facturas"
              :fields="[
                { key: 'factura', label: 'Factura' },
                { key: 'fecha', label: 'Ingresada' },
              ]"
            >
              <template #cell(factura)="data">
                {{ data.item.factura }}
              </template>
              <template #cell(fecha)="data">
                {{ data.item.fecha }}
              </template>
            </b-table>
          </div>

        </b-card>
      </b-col>
    </b-row>

    <b-modal
      id="factura-modal"
      title="Ingresar factura(s)"
      cancel-title="Cancelar"
      ok-title="Guardar"
      centered
      hide-header-close
      @ok.prevent="saveInvoices"
      @hidden="invoiceNumber = ''"
    >
      <b-form-group>
        <b-input-group>
          <b-form-input
            v-model="invoiceNumber"
            placeholder="Ingrese el número de factura"
          />
          <b-input-group-append>
            <b-button
              variant="secondary"
              size="sm"
              @click="addInvoiceItem(invoiceNumber)"
            >
              <feather-icon
                icon="PlusIcon"
                size="16"
              />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>

      <!-- Lista de facturas -->
      <b-list-group>
        <b-list-group-item
          v-for="(item, index) in invoiceItems"
          :key="index"
        >
          <div class="d-flex justify-content-between align-items-center">
            <span>{{ item }}</span>
            <feather-icon
              icon="TrashIcon"
              size="16"
              @click="invoiceItems.splice(index, 1)"
            />
          </div>
        </b-list-group-item>
      </b-list-group>

    </b-modal>
  </section>
</template>

<script>
import axios from '@axios'
import Ripple from 'vue-ripple-directive'

export default {

  directives: {
    Ripple,
  },

  props: {
    orderId: {
      type: Number,
      default: () => 0,
    },
  },

  data() {
    return {
      customerOrderData: {
        Id: '',
        Estado: '',
        FechaPedidoCliente: '',
        FechaIngresoSistema: '',
        UsuarioNombre: '',
        SummaryInfo: [],
        Detalle: [],
        Comentarios: [],
        Observaciones: '',
        Vendedor: '',
        CustomerInfo: [],
      },

      CustomerOrderFields: [
        { key: 'producto', label: 'Producto' },
        { key: 'producto_desc', label: 'Descripción' },
        { key: 'cantidad', label: 'Cantidad' },
        { key: 'precio_pedido', label: 'Precio' },
        { key: 'total', variant: 'success' },
      ],

      anulated: false,
      showPrint: false,

      commentText: '',
      disabledAction: 'enabled',

      invoiceNumber: '',
      invoiceItems: [],
    }
  },

  mounted() {
    if (this.orderId > 0) {
      this.getCustomerOrderData()
    }
  },

  methods: {
    getCustomerOrderData() {
      axios
        .get(`accounting-system/customer-order/${this.orderId}`)
        .then(response => {
          this.customerOrderData.Id = response.data.data.Id
          this.customerOrderData.Observaciones = (response.data.data.Observaciones) ? response.data.data.Observaciones : '-'
          this.customerOrderData.Detalle = response.data.data.Detalle
          this.customerOrderData.SummaryInfo = response.data.data.SummaryInfo
          this.customerOrderData.FechaIngresoSistema = response.data.data.FechaIngresoSistema
          this.customerOrderData.FechaPedidoCliente = response.data.data.FechaPedidoCliente
          this.customerOrderData.UsuarioNombre = response.data.data.UsuarioNombre
          this.customerOrderData.Estado = response.data.data.Estado
          this.customerOrderData.Comentarios = response.data.data.Comentarios
          this.customerOrderData.Facturas = response.data.data.Facturas
          this.customerOrderData.Observaciones = response.data.data.Observaciones
          this.customerOrderData.Vendedor = response.data.data.UsuarioNombre
          // Info del cliente
          this.customerOrderData.CustomerInfo = response.data.data.CustomerInfo

          if (response.data.data.Estado === 'Cancelado') {
            this.anulated = true
          }
        })
        .catch(error => {
          this.showErrors(error)
        })
    },

    getCurrentDateTime() {
      const date = new Date()
      const dateStr = `${(`00${date.getMonth() + 1}`).slice(-2)}/${
        (`00${date.getDate()}`).slice(-2)}/${
        date.getFullYear()} ${
        (`00${date.getHours()}`).slice(-2)}:${
        (`00${date.getMinutes()}`).slice(-2)}:${
        (`00${date.getSeconds()}`).slice(-2)}`

      return dateStr
    },

    getStatusVariant(estado) {
      switch (estado) {
        case 'Pendiente':
          return 'info'
        case 'Autorizado':
          return 'success'
        case 'Correcciones':
          return 'warning'
        case 'Rechazado':
          return 'danger'
        case 'Anulado':
          return 'danger'
        case 'Despachado':
          return 'secondary'
        case 'Facturado':
          return 'dark'
        default:
          return 'secondary'
      }
    },

    changeStatus(estado) {
      let alertMsg = ''
      let successMsg = ''

      switch (estado) {
        case 'Autorizado':
          alertMsg = '¿Está seguro de autorizar el pedido?'
          successMsg = 'Pedido autorizado correctamente.'
          break
        case 'Despachado':
          alertMsg = '¿Está seguro de marcar el pedido como despachado?'
          successMsg = 'Pedido marcado como despachado correctamente.'
          break
        case 'Correcciones':
          alertMsg = '¿Está seguro de solicitar correcciones en el pedido?'
          successMsg = 'Correcciones solicitadas correctamente.'
          break
        case 'Rechazado':
          alertMsg = '¿Está seguro de rechazar el pedido?'
          successMsg = 'Pedido rechazado correctamente.'
          break
        case 'Anulado':
          alertMsg = '¿Está seguro de anular el pedido?'
          successMsg = 'Pedido anulado correctamente.'
          break
        case 'Facturado':
          this.$bvModal.show('factura-modal')
          return // Salir del método para evitar ejecutar el resto del código
        default:
          alertMsg = '¿Está seguro de realizar esta acción?'
          successMsg = 'Acción realizada correctamente.'
          break
      }

      this.$bvModal
        .msgBoxConfirm(alertMsg, {
          title: 'Atención',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          okTitleVariant: 'outline-danger',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            axios
              .put(`accounting-system/customer-order/${this.orderId}/status/${estado}`)
              .then(response => {
                if (response.data.res) {
                  this.makeToast('success', 'Actualizado', successMsg)
                  this.getCustomerOrderData()
                } else {
                  throw (response)
                }
              })
              .catch(error => {
                this.showErrors(error)
              })
          }
        })
    },

    saveInvoices() {
      if (this.invoiceItems.length === 0) {
        this.makeToast('warning', 'Alerta', 'Debe ingresar al menos una factura.')
        return
      }

      const invoices = {
        idpedidos_enc: this.orderId,
        facturas: this.invoiceItems,
      }

      axios
        .post('accounting-system/customer-order-invoices', invoices)
        .then(response => {
          if (response.data.res) {
            this.makeToast('success', response.data.msg, response.data.data)
            this.getCustomerOrderData()
            this.$bvModal.hide('factura-modal')
            this.invoiceItems = []
          } else {
            throw (response)
          }
        })
        .catch(error => {
          this.showErrors(error)
        })
    },

    editInvoices() {
      this.$bvModal.show('factura-modal')
      this.invoiceItems = this.customerOrderData.Facturas.map(item => item.factura)
    },

    addInvoiceItem(item) {
      if (item === '') {
        return
      }

      if (this.invoiceItems.includes(item)) {
        this.makeToast('warning', 'Alerta', 'Esta factura ya fue ingresada.')
        return
      }

      this.invoiceItems.push(item)
      this.invoiceNumber = ''
    },

    userData() {
      return JSON.parse(localStorage.getItem('KultiverAuthUser'))
    },

    addComment() {
      this.disabledAction = 'disabled'
      if (this.commentText.length > 0) {
        const comment = {
          idpedidos_enc: this.orderId,
          comentario: this.commentText,
        }

        axios
          .post('accounting-system/customer-order-comment', comment)
          .then(response => {
            if (response.data.res) {
              this.makeToast('success', 'Comentario agregado', 'Comentario agregado correctamente.')
              this.commentText = ''
              this.disabledAction = 'enabled'
              this.getCustomerOrderData()
            } else {
              throw (response)
            }
          })
          .catch(error => {
            this.showErrors(error)
          })
      }
    },

  },
}
</script>

<style lang="scss">

td,
th,
tr,
table {
    border-top: 1px solid black;
    border-collapse: collapse;
}

td.producto,
th.producto {
    width: 145px;
    max-width: 145px;
}

td.cantidad,
th.cantidad {
    width: 110px;
    max-width: 110px;
    word-break: break-all;
}

td.precio,
th.precio {
    width: 70px;
    max-width: 70px;
    word-break: break-all;
}

.centrado {
    text-align: center;
    align-content: center;
}

.ticket {
    width: 240px;
    max-width: 240px;
    font-size: 10px;
}

img {
    max-width: inherit;
    width: inherit;
}

.total_size {
  font-size:15px
}
</style>
