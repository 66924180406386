<template>
  <section class="invoice-add-wrapper">

    <validation-observer ref="simpleRules">
      <b-form
        v-if="show"
      >
        <b-row>
          <b-col
            cols="12"
            md="5"
            xl="5"
          >
            <b-card
              class="order-add-wrapper"
              no-body
            >
              <b-card-body>
                <b-row>
                  <b-col
                    cols="12"
                    xl="6"
                    md="6"
                  >
                    <h6>
                      <b>Cliente:</b>
                    </h6>
                    <validation-provider
                      v-slot="{ errors }"
                      name="cliente"
                      rules="required"
                    >
                      <b-form-group>
                        <v-select
                          v-if="customersOptions"
                          ref="select"
                          input-id="select-customers"
                          :options="customersOptions"
                          :label="customerSelectLabel"
                          placeholder="Selecciona un cliente"
                          @input="val => setCustomerData(val)"
                          @search="onSearchCustomers"
                        >
                          <template #list-header>
                            <li
                              v-if="userData().role === 'admin'"
                              class="add-new-customer-header d-flex align-items-center my-50"
                              @click="customerAddSidebarActive = true"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                size="16"
                              />
                              <span class="align-middle ml-25">Nuevo cliente</span>
                            </li>
                          </template>
                          <template #option="option">
                            {{ option.nit }} - {{ option.label }}
                          </template>
                          <template slot="no-options">
                            No se encontraron clientes
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- Col: Fecha -->
                  <b-col
                    cols="12"
                    xl="6"
                    md="6"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="fecha"
                      rules="required"
                    >
                      <b-form-group>
                        <h6>
                          <b>Fecha:</b>
                        </h6>
                        <b-form-datepicker
                          v-model="customerOrderData.fecha_pedido"
                          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                          locale="es"
                          placeholder="Fecha de la venta"
                          selected-variant="warning"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>

                <b-row
                  v-if="userData().role === 'admin'"
                >
                  <b-col
                    cols="12"
                    xl="12"
                    md="12"
                  >
                    <h6>
                      <b>Vendedor:</b>
                    </h6>
                    <validation-provider
                      v-slot="{ errors }"
                      name="vendedor"
                      rules="required"
                    >
                      <b-form-group>
                        <v-select
                          v-if="vendorsOptions"
                          ref="select"
                          input-id="select-vendors"
                          :options="vendorsOptions"
                          label="label"
                          placeholder="Selecciona un vendedor"
                          @input="val => customerOrderData.idusuario = val.id"
                        >
                          <template #option="option">
                            {{ option.id }} - {{ option.label }}
                          </template>
                          <template slot="no-options">
                            No se encontraron vendedores
                          </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col
                    cols="12"
                    xl="12"
                    md="12"
                  >
                    <!-- Info del cliente -->
                    <p v-if="customerData.identidades"><!-- Nombre --><span class="font-weight-bold">Nombre:</span>
                      {{ customerData.nombre }}
                      <br>
                      <!-- NIT -->
                      <span class="font-weight-bold">NIT:</span>
                      {{ (customerData.nit_cf === 'Y') ? `Consumidor final - ${customerData.nit}` : customerData.nit }}
                      <br>
                      <!-- Tipo de cliente -->
                      <span class="font-weight-bold">Tipo de cliente:</span>
                      {{ (customerData.tipo_desc) ? customerData.tipo_desc : '' }}
                      <br>
                      <!-- Lista de precios -->
                      <span class="font-weight-bold">Lista de precios:</span>
                      {{ (customerData.listap_desc) ? customerData.listap_desc : '' }}
                      <br>
                      <!-- Dirección -->
                      <span class="font-weight-bold">Dirección de cobro:</span>
                      {{ (customerData.direccion) ? customerData.direccion : '' }}
                      <br>
                      <!-- Dirección de factura -->
                      <span class="font-weight-bold">Dirección de factura:</span>
                      {{ (customerData.direccion_factura) ? customerData.direccion_factura : '' }}
                      <br>
                      <!-- Dirección de entrega -->
                      <span class="font-weight-bold">Dirección de entrega:</span>
                      {{ (customerData.direccion_entrega) ? customerData.direccion_entrega : '' }}
                      <br>
                      <!-- Departamento y municipio -->
                      <span class="font-weight-bold">Departamento:</span>
                      {{ (customerData.depto) ? customerData.depto : '-' }}
                      <br>
                      <span class="font-weight-bold">Municipio:</span>
                      {{ (customerData.muni) ? customerData.muni : '-' }}
                    </p>
                  </b-col>
                  <b-col
                    cols="12"
                    md="12"
                    xl="12"
                  >
                    <span class="font-weight-bold"><b>Observaciones:</b></span>
                    <b-form-textarea
                      v-model="customerOrderData.observaciones"
                      placeholder="Comentarios y anotaciones relacionadas con el pedido"
                    />
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>

          <!-- Pedido -->
          <b-col
            v-if="customerData.identidades"
            cols="12"
            md="7"
            xl="7"
          >
            <b-card
              class="order-add-wrapper"
              no-body
            >
              <p class="mb-1 mt-1 ml-2">
                <b>Detalle del pedido:</b>
              </p>
              <b-card-body class="form-item-section">
                <div
                  ref="form"
                  class="repeater-form"
                  :style="{height: trHeight}"
                >
                  <div
                    v-for="(product, index) in customerOrderData.products"
                    :key="index"
                    ref="row"
                    class="pb-1"
                  >
                    <div class="d-flex border rounded">
                      <b-col
                        v-if="product.label !== ''"
                        cols="4"
                        md="4"
                        xl="4"
                        class="col-items-customer-order"
                      >
                        {{ product.label }} - {{ product.description }}
                      </b-col>

                      <b-col
                        v-if="product.label === ''"
                        cols="4"
                        md="4"
                        xl="4"
                        class="col-items-customer-order"
                      >
                        <v-select
                          v-if="productsOptionsSelect"
                          input-id="select-products"
                          label="descripcion"
                          :options="productsOptionsSelect"
                          placeholder="Selecciona un producto"
                          style="font-size: 10px;"
                          @input="val => setProductData(index, val)"
                          @search="onSearchProductsSelect"
                        >
                          <template #option="option">
                            {{ option.label }} - {{ option.descripcion }}
                          </template>
                          <template slot="no-options">
                            No se encontraron productos
                          </template>
                        </v-select>
                      </b-col>

                      <b-col
                        cols="3"
                        md="3"
                        xl="3"
                        class="col-items-customer-order"
                      >
                        <b-form-input
                          :id="`product-price-${index}`"
                          v-model="product.price"
                          type="number"
                          min="0.01"
                          step="any"
                          size="sm"
                        />
                      </b-col>
                      <b-col
                        cols="2"
                        class="col-items-customer-order"
                      >
                        <b-form-input
                          v-model="product.quantity"
                          type="number"
                          min="1"
                          step="any"
                          size="sm"
                        />
                      </b-col>
                      <b-col
                        cols="2"
                        md="2"
                        xl="2"
                        class="col-items-customer-order"
                      >
                        <p
                          style="padding-top: 5px;"
                        >
                          <b>Q.{{ Number(product.price * product.quantity).toFixed(2) }}</b>
                        </p>
                      </b-col>
                      <b-col
                        cols="1"
                        md="1"
                        xl="1"
                      >
                        <div class="d-flex flex-column justify-content-between border-left">
                          <feather-icon
                            size="20"
                            icon="XIcon"
                            class="cursor-pointer"
                            style="padding-bottom: 5px;"
                            @click="removeItem(index)"
                          />

                          <feather-icon
                            :id="`form-item-settings-icon-${index}`"
                            size="20"
                            icon="SettingsIcon"
                            class="cursor-pointer"
                            style="padding-top: 5px;"
                            @click="getProductHistoryPrices(product.label)"
                          />

                          <b-popover
                            :ref="`form-item-settings-popover-${index}`"
                            :target="`form-item-settings-icon-${index}`"
                            triggers="click"
                            placement="lefttop"
                          >
                            <b-form @submit.prevent>
                              <b-row>
                                <b-col cols="12">
                                  <b-form-group
                                    label="Historial de precios"
                                    :label-for="`setting-item-${index}-history-price-${index}`"
                                  >
                                    <b-table
                                      v-if="productHistoryPrices"
                                      empty-text="No hay ventas registradas"
                                      select-mode="range"
                                      sticky-header="true"
                                      small
                                      :items="productHistoryPrices"
                                    />
                                  </b-form-group>
                                </b-col>
                                <b-col
                                  cols="12"
                                  class="d-flex justify-content-between mt-1"
                                >
                                  <b-button
                                    variant="outline-secondary"
                                    @click="() => {$refs[`form-item-settings-popover-${index}`][0].$emit('close')}"
                                  >
                                    Cerrar
                                  </b-button>
                                </b-col>
                              </b-row>
                            </b-form>
                          </b-popover>
                        </div>
                      </b-col>
                    </div>
                  </div>
                </div>
              </b-card-body>

              <b-card-body>
                <b-row>
                  <!-- Add button -->
                  <b-button
                    size="sm"
                    variant="secondary"
                    class="mb-1 mt-1 ml-1"
                    @click="addBlankItem()"
                  >
                    Agregar producto
                  </b-button>
                </b-row>
              </b-card-body>

              <!-- Invoice Description: Total -->
              <b-card-body class="invoice-padding pb-0">
                <b-row>
                  <!-- Col: Total -->
                  <b-col
                    cols="12"
                    md="10"
                  >
                    <div class="sale-total-wrapper">
                      <div
                        class="sale-total-item"
                      >
                        <p class="sale-total-title">
                          Total:
                        </p>
                        <p class="sale-total-amount">
                          Q.{{ customerOrderTotal }}
                        </p>
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <!-- Spacer -->
                <hr class="invoice-spacing">
              </b-card-body>

              <div class="m-2">
                <b-col
                  sm="12"
                  md="12"
                  xl="12"
                  style="float:right;"
                >
                  <b-button
                    v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                    variant="info"
                    block
                    :disabled="!customerOrderData.products.length || (!customerOrderData.identidades || customerOrderData.identidades === '') || disabledAction === 'disabled'"
                    @click="saveData()"
                  >
                    Guardar pedido
                  </b-button>
                </b-col>
              </div>
            </b-card>
          </b-col>
        </b-row>

        <!-- Customer form -->
        <customers-add
          :customer-add-sidebar-active.sync="customerAddSidebarActive"
          @refreshData="getCustomers"
        />

      </b-form>
    </validation-observer>

  </section>
</template>

<script>
import axios from '@axios'
import { heightTransition } from '@core/mixins/ui/transition'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import CustomersAdd from '../../contacts/customers/CustomersAdd.vue'

export default {
  components: {
    vSelect,
    CustomersAdd,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],

  data() {
    return {
      // Sale
      customerOrderData: {
        identidades: '',
        idusuario: this.userData().id,
        fecha_pedido: this.getCurrentDate(),
        observaciones: '',
        products: [],
      },

      // customers
      customersOptions: [],
      customerAddSidebarActive: false,

      vendorsOptions: [],

      // Products
      productsOptions: [],
      productsOptionsSelect: [],
      productFields: [
        { key: 'label', label: 'Producto' },
        { key: 'descripcion', label: 'Descripción' },
        { key: 'precio', label: 'Precio' },
      ],
      selectedProducts: [],
      show: true,
      searchQuery: '',
      categoryOptions: [],
      selectedCategory: '',

      customerSelectLabel: 'label',
      customerData: {},
      customerLP: '',

      // pagination
      perPage: 10,
      perPageProductsOptions: [5, 10, 25, 50, 100, 'Todos'],
      currentPage: 1,
      productsMeta: null,

      sortField: '',
      sortDesc: 'desc',

      tableIsBusy: false,

      productHistoryPrices: [],

      selectedPrice: 0,

      disabledAction: 'enabled',
    }
  },

  computed: {
    customerOrderTotal() {
      let total = 0
      this.customerOrderData.products.forEach(element => {
        total += element.price * element.quantity
      })
      return Number(total).toFixed(2)
    },
  },

  watch: {
    selectedCategory() {
      this.tableIsBusy = true
      this.getProducts()
    },
    searchQuery() {
      this.tableIsBusy = true
      this.getProducts(true)
    },

    // pagination
    perPage() {
      this.tableIsBusy = true
      this.getProducts()
    },
    currentPage() {
      this.tableIsBusy = true
      this.getProducts()
    },
  },

  created() {
    this.getCustomers()
    // si el usuario es admin, obtener vendedores
    if (this.userData().role === 'admin') {
      this.getVendors()
    }

    if (this.customerOrderData.identidades > 0) {
      window.addEventListener('resize', this.initTrHeight)
    }
  },

  destroyed() {
    if (this.customerOrderData.identidades > 0) {
      window.removeEventListener('resize', this.initTrHeight)
    }
  },

  methods: {
    userData() {
      return JSON.parse(localStorage.getItem('KultiverAuthUser'))
    },

    getCustomers() {
      this.customersOptions = []
      const filterArray = [
        { field: 'cliente', value: 1 },
        { field: 'activo', value: 0 },
      ]
      axios
        .get(`accounting-system/entities-user?filters=${JSON.stringify(filterArray)}&perPage=10&sortField=identidades&sortDesc=desc&filterField=status&filterValue=Y`)
        .then(response => {
          response.data.data.forEach(element => {
            this.customersOptions.push({
              label: element.nombre,
              id: element.identidades,
              nit: element.nit,
            })
          })
        })
        .catch(error => {
          this.showErrors(error)
        })
    },

    getVendors() {
      this.vendorsOptions = []
      const filterArray = [
        // { field: 'role', value: 'vendor' },
        { field: 'active', value: 'Y' },
      ]

      axios
        .get(`system/users?filters=${JSON.stringify(filterArray)}`)
        .then(response => {
          response.data.data.forEach(element => {
            this.vendorsOptions.push({
              label: element.name,
              id: element.id,
            })
          })
        })
        .catch(error => {
          this.showErrors(error)
        })
    },

    isNIT(value) {
      if (/^\d+$/.test(value) || value === 'CF') {
        return true
      }
      return false
    },

    onSearchCustomers(search, loading) {
      // validar si search es un numero
      if (this.isNIT(search)) {
        this.customerSelectLabel = 'nit'
      } else {
        this.customerSelectLabel = 'label'
      }

      const filterArray = [
        { field: 'cliente', value: 1 },
        { field: 'activo', value: 0 },
      ]

      if (search.length) {
        loading(true)
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          axios
            .get(`accounting-system/entities-user?filters=${JSON.stringify(filterArray)}&query=${search}`)
            .then(response => {
              loading(false)
              this.customersOptions = []
              response.data.data.forEach(element => {
                this.customersOptions.push({
                  label: element.nombre,
                  id: element.identidades,
                  nit: element.nit,
                })
              })
            })
            .catch(error => {
              this.showErrors(error)
            })
        }, 300)
      } else {
        this.getCustomers()
      }
    },

    setCustomerData(customer) {
      const countItems = this.customerOrderData.products.length

      // clear all
      if (countItems > 0) {
        this.customerOrderData.products.forEach(() => {
          this.trTrimHeight(this.$refs.row[0].offsetHeight)
        })
        this.customerOrderData.products = []
      }

      if (customer) {
        // get especific customer
        axios
          .get(`accounting-system/entities/${customer.id}`)
          .then(response => {
            this.tableIsBusy = true
            this.customerOrderData.identidades = response.data.data.identidades
            this.customerData = response.data.data
            this.customerLP = response.data.data.listap
            this.getProducts()

            this.addBlankItem()
            this.initTrHeight()
          })
          .catch(error => {
            this.showErrors(error)
          })
      }
    },

    getProducts(search = false) {
      this.productsOptions = []
      this.productsMeta = null

      if (this.perPage === 'Todos') {
        this.perPage = 500
      }

      const filterArray = [
        { field: 'activo', value: 0 },
      ]

      if (search) {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          axios
            .get(`accounting-system/product-price-list-detail/${this.customerLP}?perPage=${this.perPage}&filters=${JSON.stringify(filterArray)}&query=${this.searchQuery}&sortField=${this.sortField}&sortDesc=${this.sortDesc}`)
            .then(response => {
              this.tableIsBusy = false
              response.data.data.forEach(element => {
                this.productsOptions.push({
                  id: element.idproductos,
                  label: element.producto,
                  precio: element.precio,
                  descripcion: element.descrip,
                })
                this.productsMeta = response.data
              })
            })
            .catch(error => {
              this.tableIsBusy = false
              this.showErrors(error)
            })
        }, 500)
      } else {
        axios
          .get(`accounting-system/product-price-list-detail/${this.customerLP}?perPage=${this.perPage}&page=${this.currentPage}&filters=${JSON.stringify(filterArray)}&query=${this.searchQuery}&sortField=${this.sortField}&sortDesc=${this.sortDesc}`)
          .then(response => {
            this.tableIsBusy = false
            response.data.data.forEach(element => {
              this.productsOptions.push({
                id: element.idproductos,
                label: element.producto,
                precio: element.precio,
                descripcion: element.descrip,
              })
              this.productsMeta = response.data
            })
          })
          .catch(error => {
            this.tableIsBusy = false
            this.showErrors(error)
          })
      }
    },

    onSearchProductsSelect(search, loading) {
      this.productsOptionsSelect = []

      const filterArray = [
        { field: 'activo', value: 0 },
      ]

      if (search.length) {
        loading(true)
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          axios
            .get(`accounting-system/product-price-list-detail/${this.customerLP}?filters=${JSON.stringify(filterArray)}&query=${search}`)
            .then(response => {
              loading(false)
              response.data.data.forEach(element => {
                this.productsOptionsSelect.push({
                  id: element.idproductos,
                  label: element.producto,
                  precio: element.precio,
                  descripcion: element.descrip,
                })
              })
            })
            .catch(error => {
              loading(false)
              this.showErrors(error)
            })
        }, 500)
      }
    },

    getProductHistoryPrices(productLabel) {
      // Consumir servicio para obtener histórico de precios
      axios
        .get(`accounting-system/product/hist?nit=${this.customerData.nit}&producto=${productLabel}`)
        .then(async response => {
          if (response) {
            this.productHistoryPrices = response.data
          } else {
            throw (response)
          }
        })
        .catch(error => {
          this.showErrors(error)
        })
    },

    saveData() {
      this.disabledAction = 'disabled'
      this.$bvModal
        .msgBoxConfirm('¿Deseas crear el pedido?', {
          title: 'Confirmar',
          size: 'sm',
          okVariant: 'info',
          okTitle: 'SI',
          cancelTitle: 'NO',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            axios
              .post('accounting-system/customer-order', this.customerOrderData)
              .then(async response => {
                if (response.data.res) {
                  this.makeToast('success', 'Nuevo pedido', `Se ha realizado el pedido correctamente, transacción: #${response.data.data.Id}.`)
                  await new Promise(resolve => { window.setTimeout(resolve, 500) })
                  this.$emit('addOrderTab', parseInt(response.data.data.Id, 10))
                } else {
                  throw (response)
                }
              })
              .catch(error => {
                this.showErrors(error)
              })
          }
        })
    },

    // Métodos para manejar productos dinamicamente
    // =======================================================
    setProductData(index, val) {
      if (val === null) {
        this.customerOrderData.products[index].product_id = 0
        this.customerOrderData.products[index].label = ''
        this.customerOrderData.products[index].description = ''
        this.customerOrderData.products[index].price = 0
        this.customerOrderData.products[index].quantity = 1
      } else {
        this.customerOrderData.products[index].product_id = val.id
        this.customerOrderData.products[index].label = val.label
        this.customerOrderData.products[index].description = val.descripcion
        this.customerOrderData.products[index].price = val.precio
        this.customerOrderData.products[index].quantity = 1

        /* const foundItem = this.customerOrderData.products.find(element => element.product_id === val.id)
        if (foundItem) {
          this.makeToast('danger', 'Producto ya seleccionado', `El producto ${val.descripcion} ya fue seleccionado anteriormente.`)
          this.removeItem(index)
        } else {
          this.customerOrderData.products[index].product_id = val.id
          this.customerOrderData.products[index].label = val.label
          this.customerOrderData.products[index].description = val.descripcion
          this.customerOrderData.products[index].price = val.precio
          this.customerOrderData.products[index].quantity = 1
        } */
      }
    },

    removeItem(index) {
      this.customerOrderData.products.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },

    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },

    addBlankItem() {
      this.customerOrderData.products.push({
        label: '',
        product_id: 0,
        price: 0,
        quantity: 0,
      })

      if (this.customerOrderData.products.length > 1) {
        this.$nextTick(() => {
          this.trAddHeight(this.$refs.row[0].offsetHeight)
          setTimeout(() => {
            this.$refs.form.style.overflow = null
          }, 350)
        })
      }
    },
    // =======================================================
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.order-add-wrapper {
  .add-new-customer-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}

.sale-total-wrapper {
  width: 100%;
  max-width: 10rem;
  .sale-total-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 2rem;
    .sale-total-title {
      margin-bottom: 0.35rem;
    }
    .sale-total-amount {
      margin-bottom: 0.35rem;
      font-weight: 600;
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
.per-page-selector {
  width: 90px;
}

.col-items-customer-order {
  @media (max-width: 600px) {
    font-size: 10px;
  }

  padding-top: 9px;
  padding-bottom: 0px;
  padding-right: 2px;
  padding-left: 1px;
}
</style>
